/* ========================================================================
     Component: inputs
 ========================================================================== */


/**
 * Custom form elements
 *    - Checkbox
 *    - Radios
 *    - Switch
 *    - Note editor
 */

//
// CHECKBOX AND RADIOS
//
$ui-fg-color: $primary;
$ui-bg-color: #ddd;
@mixin switch-variant($wd, $hg) {
    & span {
        width: $wd;
        height: $hg;
    }
    & span:after {
        height: $hg - 2;
        width: $hg - 2;
    }
}

.c-checkbox,
.c-radio {
    margin-right: 4px;
    * {
        cursor: pointer;
    }
    input {
        opacity: 0;
        position: absolute;
        margin-left: 0 !important;
    } // the element that replaces the input
    span {
        position: relative;
        display: inline-block;
        vertical-align: top; // margin-left: -20px;
        width: 20px;
        height: 20px;
        border-radius: 2px;
        border: 1px solid #ccc;
        margin-right: 5px;
        text-align: center;
        &:before {
            margin-left: 1px; // fa align trick
        }
    }
    &:hover span {
        border-color: $ui-fg-color;
    }
    .form-inline & span {
        margin-left: 0;
    }
    &.c-checkbox-rounded,
    &.c-radio-rounded {
        span {
            border-radius: 500px;
        }
    }
}


/* override for radio */

.c-radio span {
    border-radius: 500px;
}


/* the icon */

.c-checkbox,
.c-radio {
    span:before {
        //content: "\2713"; /* comment content to use custom icon classes */
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        text-align: center !important;
        font-size: 12px;
        line-height: 18px;
        vertical-align: middle;
    }
}

.c-checkbox,
.c-radio {
    /* Checked state */
    input[type=checkbox]:checked+span:before,
    input[type=radio]:checked+span:before {
        color: #fff;
        opacity: 1;
        transition: color .3s ease-out;
    }
    input[type=checkbox]:checked+span,
    input[type=radio]:checked+span {
        border-color: $ui-fg-color;
        background-color: $ui-fg-color;
    }
    /* override for radio */
    input[type=radio]:checked+span {
        background-color: #fff;
        &:before {
            color: $ui-fg-color;
        }
    }
    /* Disable state */
    input[type=checkbox]:disabled+span,
    input[type=radio]:disabled+span {
        border-color: $ui-bg-color !important;
        background-color: $ui-bg-color !important;
    }
    /* override for radio */
    input[type=radio]:disabled+span {
        background-color: #fff !important;
        &:before {
            color: $ui-bg-color;
        }
    }
    /* Focus state */
    input[type=checkbox]:focus+span,
    input[type=radio]:focus+span {
        box-shadow: 0 0 0 0.2rem rgba($ui-fg-color, .25)
    }
}

.c-radio.c-radio-nofont {
    span {
        &:before {
            $sz: 10px;
            content: "";
            width: $sz;
            height: $sz;
            top: 50%;
            left: 50%;
            margin-top: -($sz*0.5);
            margin-left: -($sz*0.5);
            border-radius: 500px;
        }
    }
    input[type=radio]:checked+span:before {
        color: #fff;
        opacity: 1;
        transition: color .3s ease-out;
    }
    input[type=radio]:checked+span {
        border-color: $ui-fg-color;
        background-color: $ui-fg-color;
    }
    /* override for radio */
    input[type=radio]:checked+span {
        background-color: #fff;
        &:before {
            background-color: $ui-fg-color;
        }
    }
    /* Disable state */
    input[type=radio]:disabled+span {
        border-color: $ui-bg-color !important;
        background-color: $ui-bg-color !important;
    }
    /* override for radio */
    input[type=radio]:disabled+span {
        background-color: #fff !important;
        &:before {
            background-color: $ui-bg-color;
        }
    }
}

.switch {
    background: $danger;
    &.checked {
        background: $success;
    }
    .switch-pane span {
        color: #fff;
    }
}