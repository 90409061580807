/* You can add global styles to this file, and also import other style files */

//== Vendor
@import '~@fortawesome/fontawesome-free/css/brands.css';
@import '~@fortawesome/fontawesome-free/css/regular.css';
@import '~@fortawesome/fontawesome-free/css/solid.css';
@import '~@fortawesome/fontawesome-free/css/fontawesome.css';
@import '~simple-line-icons/css/simple-line-icons.css';
@import '~spinkit/css/spinkit.css';
@import '~loaders.css/loaders.css';
@import '~ngx-toastr/toastr.css';
@import '~ag-grid-community/dist/styles/ag-grid.css';
@import '~ag-grid-community/dist/styles/ag-theme-fresh.css';
@import '~ika.jvectormap/jquery-jvectormap-1.2.2.css';
@import '~jqcloud2/dist/jqcloud.css';
@import '~summernote/dist/summernote.css';
@import '~@circlon/angular-tree-component/css/angular-tree-component.css';
@import '~@swimlane/ngx-datatable/index.css';
@import '~@swimlane/ngx-datatable/themes/material.css';
@import '~@swimlane/ngx-datatable/themes/bootstrap.css';
@import '~@swimlane/ngx-datatable/assets/icons.css';
//== Bootstrap
@import "./app/shared/styles/bootstrap.scss";
//== Application
@import "./app/shared/styles/app.scss";
//smart modal
$dialog-position-top: 10%;
@import "~ngx-smart-modal/ngx-smart-modal";
@import url("./assets/css/ngx-smartmodal-custom.scss");
@import '../node_modules/ngx-ui-switch/ui-switch.component.scss';
//froala styles
.alternateRow tbody tr:nth-child(2n) {
    background: #f9f9f9;
}

.thinBorder thead tr th,
.thinBorder tbody tr td {
    border-style: solid;
    border-width: 1px;
}

.thickBorder thead tr th,
.thickBorder tbody tr td {
    border-width: 2px;
}

.blackBorder thead tr th,
.blackBorder tbody tr td {
    border-color: black;
}

.grayBorder thead tr th,
.grayBorder tbody tr td {
    border-color: #f9f9f9;
}

.white-space-normal {
    white-space: normal;
}

.readonly .ngx-select__disabled {
    background: none !important;
}

.readonly .ngx-select__clear {
    display: none !important;
}

.readonly .ngx-select_multiple .ngx-select__search {
    display: none !important;
}

.readonly .ngx-select__toggle-buttons {
    display: none !important;
}

.disabled {
    pointer-events: none;
    opacity: 0.5;
}

.hidden {
    display: none;
}
