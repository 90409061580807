.msg-modal-700px {
  width: 700px !important;
  max-width: none !important;
}

.msg-modal {
  width: 75% !important;
  max-width: none !important;
  border-radius: 10px !important;
  padding: 15px !important;
}

.msg-modal-width {
  width: 80% !important;
  max-width: none !important;
  border-radius: 10px !important;
  padding: 15px !important;
  margin: 0%;
}

.msg-modal-width-50 {
  width: 50% !important;
  max-width: none !important;
  border-radius: 10px !important;
  padding: 15px !important;
}

.msg-modal-width-full {
  width: 90% !important;
  min-width: 80% !important;
  max-width: none !important;
  border-radius: 10px !important;
  padding: 10px !important;
  margin-left: 185px !important;
  margin-top: -70px !important;
}
